import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import pic04 from '../assets/images/pic04.jpg'

import { graphql } from 'gatsby'
import PostLink from '../components/PostLink'

const NewsPage = ({
	data: {
		allMarkdownRemark: { edges },
	},
}) => {
	const Posts = edges
		.filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
		.map(edge => <PostLink key={edge.node.id} post={edge.node} />)

	return (
		<Layout>
			<Helmet title="News" />
			<HeaderGeneric />
			<div id="main">
				<section id="content" className="main">
					<span className="image main">
						<img src={pic04} alt="" />
					</span>
					<h2>News</h2>
					<div>{Posts}</div>
				</section>
			</div>
		</Layout>
	)
}

export default NewsPage

export const pageQuery = graphql`
	query {
		allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
			edges {
				node {
					id
					excerpt(pruneLength: 250)
					frontmatter {
						date(formatString: "MMMM DD, YYYY")
						path
						title
					}
				}
			}
		}
	}
`
